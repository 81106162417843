import React, { useContext, useState, useEffect, ChangeEvent, FormEvent, useMemo } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "../Deposit/Deposit.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { dateTime, formatedTime } from "../../../../utils/utility";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Components/Loader/Loader";
import { FormControl, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import DepositWithdrawCard from "../../../../Components/Statistics/DepositWithdrawCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";

import Button from "@mui/material/Button";
import { TextField } from "@material-ui/core";

interface Deposit {
  userName: string;
  _id: string;
  userId: string;
  amount: number;
  orderId: string;
  status: string;
  type: string;
  createdOn: string;
  reason: string;
  updatedOn: string;
  __v: number;
  txid: string;
  paymentGatewayName: string;
  mid: string;
  userMobile: string;
}

interface DepositCard {
  depositeApprovedData: {
    count: number
    totalAmount: number
  }
  depositePendingData: {
    count: number
    totalAmount: number
  }
  totalOnholdWithdrawalData: {
    count: number
    totalAmount: number
  }
  totalApprovedWithdrawalData: {
    count: number
    totalAmount: number
  }
  totalPendingWithdrawalData: {
    count: number
    totalAmount: number
  }
  totalReverseWithdrawalData: {
    count: number
    totalAmount: number
  }
  totalWithdrawalRejected: {
    count: number
    totalAmount: number
  }
}

interface DepositData {
  depositApprovedTotal: number,
  depositApprovedCount: number,
  depositRejectedTotal: number,
  depositRejectedCount: number,
  depositPendingTotal: number,
  depositPendingCount: number
}
function Deposit() {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [rejectId, setRejectId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [depositData, setDepositData] = useState<Deposit[]>([]);
  const [storedDepositData, setStoredDepositData] = useState<Deposit[]>([]);
  const [depositCardData, setDepositCardData] = useState<DepositCard>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const Navigate = useNavigate();
  const { User } = useContext(User_Context);
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchUserAmount, setSearchUserAmount] = useState<string>("");
  const [searchUserOrderId, setSearchUserOrderId] = useState<string>("");
  const [searchUserMobile, setSearchUserMobile] = useState<string>("");
  const [searchUserStatus, setSearchUserStatus] = useState<string>('All');
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [openPopup, setOpenPopup] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [depositItem, setDepositItem] = useState<Deposit>();
  const [totalUser, setTotalUser] = useState<number>(10);
  const [fetchAllData, setFetchAllData] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");
  const [rejectReasonError, setRejectReasonError] = useState<boolean>(false);
  const [rejectReasonHelperText, setRejectReasonHelperText] = useState<string>("");
  const [totalDepositData, setTotalDepositData] = useState<{ [key: string]: DepositData }>({});
  const [fundId, setFundId] = useState<string>('');





  const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserName(event.target.value);
    setCurrentPage(1)
  };

  const handleSearchUserAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserAmount(event.target.value);
    setCurrentPage(1)
  };

  const handleSearchUserOrderId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserOrderId(event.target.value);
    setCurrentPage(1)
  };

  const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserMobile(event.target.value);
    setCurrentPage(1)
  };

  const handleRejectReason = (event: ChangeEvent<HTMLInputElement>) => {
    setRejectReason(event.target.value);
    console.log('testing.............');
    
  };

  const rejectDeposit = (event: any) => {
    event.preventDefault();
    if (!rejectReason) {
      setRejectReasonError(true)
      setRejectReasonHelperText("Please enter reason")
    } else {
      setLoading(true);
      let data = {
        transactionId: rejectId,
        status: "Rejected",
        updatedBy: {
          _id: User.data._id,
          name: User.data.name
        },
        reason: rejectReason
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_Endpoint}/transaction/update-deposit-status`,
        headers: {
          Authorization: `Bearer ${User.token}`,
        },
        data: { token: encryptData(data) },
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          setOpenRejectPopup(false)
          setRejectReason("")
          setRejectReasonError(false)
          toast("Amount Rejected Successfully!");
          setDepositData((prevData) =>
            prevData.map((item) =>
              item.orderId === rejectId
                ? { ...item, status: 'Rejected' }
                : item
            )
          );
        })
        .catch((error: any) => {
          setLoading(false)
          toast("Error please try again after sometime");
        });
    }

  }
  const getFundRequests = () => {
    setLoading(true)
    const token = localStorage.getItem("token");
    let data = {}
    if (startDate && endDate) {
      data = {
        startDate: dateTime(startDate),
        endDate: dateTime(endDate)
      };
    } else if (fundId === "alldata") {
      data = {}
    } else {
      // const currentDate = new Date().toISOString().split('T')[0];
      const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
      data = {
        startDate: dateTime(currentDate),
        endDate: dateTime(currentDate)
      };
    }
    if (token) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_Endpoint}/SubAdmin/fund-request`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { token: encryptData(data) }
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data)
          setTotalDepositData(response.data.data.payload.depositData)
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }


  let deposit = "deposit";



  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }

  const filterTransactionData = (e: any) => {
    const id = e.target.id
    setFundId(id)
    if (id === "alldata") {
      setStartDate('')
      getFundRequests()
      setEndDate('')
      setCurrentPage(1)
      setFetchAllData("test")
    } else {
      setFetchAllData("")
      filterTransaction()
      getFundRequests()
      setCurrentPage(1)
    }
  }
  const filterTransaction = (event?: any) => {
    setLoading(true);
    let filter: { userName?: string, amount?: string, orderId?: string, userMobile?: string, status?: string } = {};
    if (searchUserName) {
      filter.userName = searchUserName
    }
    if (searchUserAmount) {
      filter.amount = searchUserAmount
    }
    if (searchUserOrderId) {
      filter.orderId = searchUserOrderId
    }
    if (searchUserMobile) {
      filter.userMobile = searchUserMobile
    }
    if (event && event.target) {
      if (event.target.value === 'All') {
        setSearchUserStatus('All')
        filter = {}
      } else {
        setSearchUserStatus(event.target.value);
        filter.status = (searchUserStatus?.length > 0 && searchUserStatus !== "All") ? searchUserStatus : event?.target?.value
      }
    }
    else if (searchUserStatus?.length > 0 && searchUserStatus !== "All") {
      filter.status = (searchUserStatus?.length > 0 && searchUserStatus !== "All") ? searchUserStatus : event?.target?.value
    }
    const token = localStorage.getItem("token");
    let data = {}
    if (fetchAllData) {
      data = {
        type: deposit,
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,
        filter: filter
      };
    } else if (startDate && endDate) {
      data = {
        type: deposit,
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,
        filter: filter,
        startDate: dateTime(startDate),
        endDate: dateTime(endDate)
      };
    } else {
      // const currentDate = new Date().toISOString().split('T')[0];
      const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
      data = {
        type: deposit,
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,
        filter: filter,
        startDate: dateTime(currentDate),
        endDate: dateTime(currentDate)
      };
    }
    if (token) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_Endpoint}/transaction/getAllTransaction`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { token: encryptData(data) }
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data)
          setTotalUser(response.data.data.payload.total)
          let data = response.data.data.payload.items.length <= 0
          if (data) {
            toast("No deposits available for todays date")
          }
          setDepositCardData(response.data.data.payload)
          setStoredDepositData(response.data.data.payload.items);
          setDepositData(response.data.data.payload.items);
          setTotalPages(response.data.data.payload.totalPages)
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

  };

  const formatDate = (date: any) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const formattedDate = (timestamp: any) => formatDate(new Date(timestamp));


  const openEditDialog = (item: Deposit) => {
    setDepositItem(item)
    setOpenPopup(true)
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const token = localStorage.getItem("token");
    axios
      .post(
        `${API_Endpoint}/coin/add`,
        {
          token: encryptData({
            userId: depositItem?.userId,
            balance: depositItem?.amount,
            updatedBy: {
              name: User.data.name,
              _id: User.data._id
            },
            reason: "Deposit Failure",
            remark: `Deposite failure of ${depositItem?.userName} through ${depositItem?.paymentGatewayName} pay with order id ${depositItem?.orderId} and mobile no ${depositItem?.userMobile}`,
            tag: "credit",
            orderId: depositItem?.orderId
          })
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setOpenPopup(false)
        setDepositData((prevData) =>
          prevData.map((item) =>
            item.orderId === depositItem?.orderId
              ? { ...item, status: 'Approved' }
              : item
          )
        );
        toast("Amount deposited Successfully!");
      })
      .catch((error) => {
        setOpenPopup(false)
        toast(error.response.data.message);
      });

  };
  const clearDate = () => {
    setStartDate('')
    setEndDate('')
    setFetchAllData("")
    setCurrentPage(1)
  }

  const handleRejectPopup = (orderId: string) => {
    setOpenRejectPopup(true)
    setRejectId(orderId)
  }

  useEffect(() => {
    filterTransaction()
    getFundRequests()
  }, [currentPage, itemsPerPage, fetchAllData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={"Deposit"} />
              {/* <div className="container-fluid">
                <div className="row">
                  <DepositWithdrawCard title={`Deposit Approved Amt (${depositCardData?.depositeApprovedData?.count ? depositCardData?.depositeApprovedData?.count : 0}) : ${depositCardData?.depositeApprovedData?.totalAmount ? depositCardData?.depositeApprovedData?.totalAmount : 0}`} title2={`Deposit Pending Amt (${depositCardData?.depositePendingData?.count ? depositCardData?.depositePendingData?.count : 0}) : ${depositCardData?.depositePendingData?.totalAmount ? depositCardData?.depositePendingData?.totalAmount : 0}`} />
                  <DepositWithdrawCard title={`Withdrawal Approved Amt (${depositCardData?.totalApprovedWithdrawalData?.count ? depositCardData?.totalApprovedWithdrawalData?.count : 0}) : ${depositCardData?.totalApprovedWithdrawalData?.totalAmount ? depositCardData?.totalApprovedWithdrawalData?.totalAmount : 0}`} title2={`Withdrawal Pending Amt (${depositCardData?.totalPendingWithdrawalData?.count ? depositCardData?.totalPendingWithdrawalData?.count : 0}) : ${depositCardData?.totalPendingWithdrawalData?.totalAmount ? depositCardData?.totalPendingWithdrawalData?.totalAmount : 0}`} title3={`Withdrawal Reverse Amt (${depositCardData?.totalReverseWithdrawalData?.count ? depositCardData?.totalReverseWithdrawalData?.count : 0}) : ${depositCardData?.totalReverseWithdrawalData?.totalAmount ? depositCardData?.totalReverseWithdrawalData?.totalAmount : 0}`} title4={`Withdrawal Rejected Amt : (${depositCardData?.totalWithdrawalRejected?.count ? depositCardData?.totalWithdrawalRejected?.count : 0}) : ${depositCardData?.totalWithdrawalRejected?.totalAmount ? depositCardData?.totalWithdrawalRejected?.totalAmount : 0}`} title5={`Withdrawal on Hold Amt : (${depositCardData?.totalOnholdWithdrawalData?.count ? depositCardData?.totalOnholdWithdrawalData?.count : 0}) : ${depositCardData?.totalOnholdWithdrawalData?.totalAmount ? depositCardData?.totalOnholdWithdrawalData?.totalAmount : 0}`} />
                </div>
              </div> */}
              <div className="container-fluid mt-2">
                <div className="row">
                  <div className="row tp-form mb-2">
                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                      <label className="lbl">From Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="From Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                      <label className="lbl">To Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="To Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                      <label className="lbl">Items Per Page</label>
                      <div className="mt-1">
                        <Stateful_Select
                          value={itemsPerPage.toString()}
                          onChange={(newValue: any) => handlePerPage(newValue)}
                          options={["10", "25", "50", "75", "100"]}
                          className="deposit-select"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                      <label className="lbl"></label>
                      <a onClick={filterTransactionData} className="sechBtn mt-1">
                        Apply
                      </a>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                      <label className="lbl"></label>
                      <a id="alldata" onClick={(e) => filterTransactionData(e)} className="sechBtn mt-1">
                        All Data
                      </a>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                      <label className="lbl"></label>
                      <a onClick={clearDate} className="sechBtn mt-1">
                        Clear Dates
                      </a>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
                      <label className="lbl"></label>
                      <b>Total User : {totalUser}</b>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
                      <label className="lbl"></label>
                      <b>Approved {`(${totalDepositData?.depositApprovedCount ? totalDepositData?.depositApprovedCount : 0}) : ${totalDepositData?.depositApprovedTotal ? totalDepositData?.depositApprovedTotal : 0}`}</b>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
                      <label className="lbl"></label>
                      <b>Pending {`(${totalDepositData?.depositPendingCount ? totalDepositData?.depositPendingCount : 0}) : ${totalDepositData?.depositPendingTotal ? totalDepositData?.depositPendingTotal : 0}`}</b>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
                      <label className="lbl"></label>
                      <b>Rejected {`(${totalDepositData?.depositRejectedCount ? totalDepositData?.depositRejectedCount : 0}) : ${totalDepositData?.depositRejectedTotal ? totalDepositData?.depositRejectedTotal : 0}`}</b>
                    </div>
                  </div>
                  <div className="col-12 mt-1">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>User Name</th>
                            <th>Mobile No</th>
                            <th>Transaction Id</th>
                            <th>Amount</th>
                            <th>Payment Method</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Rejected Reason</th>
                          </tr>
                        </thead>
                        <thead>
                          <tr className="bg-table">
                            <th className="thdr"></th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserName} onChange={handleSearchUserName} onSearch={filterTransaction} placeholder="Search by user name" />
                              </div>
                            </th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserMobile} onChange={handleSearchUserMob} onSearch={filterTransaction} placeholder="Search by mobile no" />
                              </div>
                            </th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserOrderId} onChange={handleSearchUserOrderId} onSearch={filterTransaction} placeholder="Search by transaction id" />
                              </div>
                            </th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserAmount} onChange={handleSearchUserAmount} onSearch={filterTransaction} placeholder="Search by amount" />
                              </div>
                            </th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center withdraw-select">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={searchUserStatus}
                                    label="Status"
                                    onChange={(event) => filterTransaction(event)}
                                  >
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"Pending"}>Pending</MenuItem>
                                    <MenuItem value={"Approved"}>Approved</MenuItem>
                                    <MenuItem value={"Rejected"}>Rejected</MenuItem>
                                    <MenuItem value={"Reverse"}>Reverse</MenuItem>
                                    <MenuItem value={"on hold"}>on hold</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </th>
                            <th className="thdr"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {depositData.map((item, index) => (
                            <tr className={
                              item.status === "Approved" ? "approved-clr" :
                                item.status === "Pending" ? "pending-clr" :
                                  (item.status === "Rejected" || item.status === "Failed" || item.status === "FAILURE") ? "rejected-clr" :
                                    ""
                            } id={item._id}>
                              <td>{index + 1}</td>
                              <td onClick={() => {
                                const url = `/user-report/${item.userId}/${item.userName}`;
                                window.open(url, "_blank");
                              }} style={{ cursor: 'pointer' }}>{item.userName}</td>
                              <td>{item.userMobile}</td>
                              <td>{item.orderId}</td>
                              <td>{item.amount}</td>
                              <td>{`${item.paymentGatewayName} - ${item.mid}`}</td>
                              <td>{formattedDate(item.createdOn)}</td>
                              <td>{formatedTime(item.createdOn)}</td>
                              <td>{item.status}
                                {item.status.toLowerCase() === "pending" && (
                                  <><span style={{ verticalAlign: "middle", marginLeft: "1rem" }}>
                                    <FontAwesomeIcon
                                      className="fa fa-pencil-square icon-home icon-banner"
                                      icon={faPencilSquare}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => openEditDialog(item)} />
                                  </span>
                                    {/* <Button onClick={(event: any) => rejectDeposit(item.orderId, event)} variant="contained" className="btn-withdraw">Reject</Button> */}
                                    {User.data.permission && (<Button onClick={() => handleRejectPopup(item.orderId)} variant="contained" className="btn-withdraw">Reject</Button>)}
                                  </>
                                )}
                              </td>
                              <td>{item.reason}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(Event, New_Page) =>
                            setCurrentPage(New_Page)
                          }
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <div>
                  <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                    <DialogContent className="flow-off">
                      <form onSubmit={handleSubmit}>
                        <h6>Manual settle Transaction</h6>
                        <div className='parent-container'>
                          <div className='centered-div'>
                            <div className='mt-2 text-inp'>
                              <label>Amount</label>
                              <TextField variant="outlined" size="small" className="mt-1" value={depositItem?.amount} disabled type={"number"} fullWidth={true} />
                            </div>
                            <div className='mt-1 text-inp'>
                              <label>Reason</label>
                              <TextField variant="outlined" size="small" className="mt-2" value={"Deposit failure"} type={"text"} disabled fullWidth={true} />
                            </div>
                            <div className='mt-1 mb-2 text-inp'>
                              <label>Remark</label>
                              <TextField variant="outlined" multiline={true}
                                rows={3} className="mt-2" value={`Deposite failure of ${depositItem?.userName} through ${depositItem?.paymentGatewayName} with order id ${depositItem?.orderId}`} type={"text"} disabled fullWidth={true} />
                            </div>

                          </div>
                        </div>
                        <DialogActions>
                          <Button
                            className="btn-popup"
                            variant="outlined"
                            onClick={() => setOpenPopup(false)}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn-popup"
                            variant="outlined"
                            type="submit"
                          //color="primary"
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </form>
                    </DialogContent>
                  </Dialog>
                </div>
                <div>
                  <Dialog open={openRejectPopup} onClose={() => setOpenRejectPopup(false)}>
                    <DialogContent className="flow-off">
                      <form onSubmit={rejectDeposit}>
                        <div className='parent-container'>
                          <div className='centered-div'>
                            <div className='mt-1 mb-2 text-inp'>
                              <label>Enter Reject Reason</label>
                              <TextField label={"Please enter the reason"} error={rejectReasonError} helperText={rejectReasonHelperText} value={rejectReason} variant="outlined" multiline={true}
                                rows={3} className="mt-2" type={"text"} fullWidth={true} onChange={handleRejectReason} />
                            </div>
                          </div>
                        </div>
                        <DialogActions>
                          <Button
                            className="btn-popup"
                            variant="outlined"
                            onClick={() => setOpenRejectPopup(false)}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn-popup"
                            variant="outlined"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </form>
                    </DialogContent>
                  </Dialog>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}
export default React.memo(Deposit);