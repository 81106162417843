import React, { useContext, useState, useEffect, ChangeEvent, FormEvent } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "./Withdrawal.css"
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Button from "@mui/material/Button";
import axios from "axios";
import { dateTime, formatedTime } from "../../../../utils/utility";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { MenuItem, Pagination, Select, SelectChangeEvent, Stack } from "@mui/material";
import {
	faSquareCheck,
	faRectangleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User_Context } from "../../../../Contexts/User";
import { formatDate } from "../../../../utils/utility";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import CustomSelect from "../../../../Components/Dropdown/CustomSelect";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup
} from "@material-ui/core";
import Reusable_Input from "../../../../Components/InputField/InputField";

interface Withdraw {
	secondRejectUserName: string;
	secondReject: boolean;
	firstReject: boolean;
	accountHolderName: string;
	commissionAmount: string;
	_id: string;
	userId: string;
	amount: number;
	orderId: string;
	status: string;
	type: string;
	createdOn: string;
	updatedOn: string;
	__v: number;
	txid: string;
	paymentGatewayName: string;
	bankName: string;
	ifscCode: string;
	mobile: string;
	accountNo: string;
	transactionId: string;
	secondCheck: boolean;
	firstCheck: boolean;
	firstCheckUserName: string;
	secondCheckUserName: string;
	firstRejectUserName: string;
	dp_id: string;
	withdrewalProviderName:string;
	mid:string;
	checkBy: {
		name: string
		userId: string
		status: boolean
		_id: string
		date:string
	}
	crossCheckBy: {
		name: string
		userId: string
		status: boolean
		_id: string
		date: string
	}
	action: {
		name: string
		userId: string
		status: boolean
		_id: string
	}
}

interface WithdrawData {
	todaysTotalApprovedAmount: number,
	todaysTotalApprovedCount: number,
	previousTotalApprovedAmount: number,
	previousTotalApprovedCount: number,
	totalApprovedAmount: number,
	totalApprovedCount: number,
	totalCanceledAmount: number,
	totalCanceledCount: number,
	totalRejectedAmount: number,
	totalRejectedCount: number,
	totalReversedAmount: number,
	totalReversedCount: number,
	totalPendingAmount: number,
	totalPendingCount: number,
	totalOnholdAmount: number,
	totalOnholdCount: number
  }

function Withdraw() {
	const [startDate, setStartDate] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [endDate, setEndDate] = useState<string>("");
	const [withdrawData, setWithdrawData] = useState<Array<Withdraw>>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const { User } = useContext(User_Context);
	const Number_of_Items_per_Page = 10;
	const [searchUserName, setSearchUserName] = useState<string>("");
	const [searchUserStatus, setSearchUserStatus] = useState<string>("");
	const [searchUserMobileNo, setSearchUserMobileNo] = useState<string>("");
	const [searchUserIfsc, setSearchUserIfsc] = useState<string>("");
	const [searchUserAccountNo, setSearchUserAccountNo] = useState<string>("");
	const [searchUserAmount, setSearchUserAmount] = useState<string>("");
	const [searchUserDpId, setSearchUserDpId] = useState<string>("");
	const [searchUserTransactionId, setSearchUserTransactionId] = useState<string>("");
	const [Payment_Gateways, Set_Payment_Gateways] = useState<string[]>([]);
	const [Selected_Payment_Gateway_Indexes, Set_Selected_Payment_Gateway_Indexes] = useState<number[]>(new Array(Number_of_Items_per_Page).fill(0));
	let withdrawl = "withdrawal";
	const [itemsPerPage, setItemsPerPage] = useState<number>(10);
	const [totalUser, setTotalUser] = useState<number>(10);
	const [openPopup, setOpenPopup] = useState<boolean>(false);
	const [remark, setRemark] = useState<string>("");
	const [remarkError, setRemarkError] = useState<boolean>(false);
	const [remarkHelperText, setRemarkHelpertext] = useState<string>("");
	const [orderIdPopup, setOrderIdPopup] = useState<string>("");
	const [actionPopup, setActionPopup] = useState<string>("");
	const [dpIdPopup, setDpIdPopup] = useState<string>("");
	const [paymentGateway, setPaymentGateway] = useState<string>('')
	const [fetchAllData, setFetchAllData] = useState<string>("");
	const [totalWithdrawData, setTotalWithdrawData] = useState<{ [key: string]: WithdrawData }>({});
    const [fundId, setFundId] = useState<string>('');


	const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserName(event.target.value);
		setCurrentPage(1)
	};

	const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserMobileNo(event.target.value);
		setCurrentPage(1)
	};

	const handleSearchUserIfsc = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserIfsc(event.target.value);
		setCurrentPage(1)
	};

	const handleSearchUserAmount = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserAmount(event.target.value);
		setCurrentPage(1)
	};

	const handleSearchUserTransactionId = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserTransactionId(event.target.value);
		setCurrentPage(1)
	};

	const handleSearchUserDpId = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserDpId(event.target.value);
		setCurrentPage(1)
	};

	const handleSearchUserAccountNo = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchUserAccountNo(event.target.value);
		setCurrentPage(1)
	};


	useEffect(() => {
		axios.request({
			method: "post",
			url: `${API_Endpoint}/payoutAccounts/getAll-active`,
			headers: { Authorization: `Bearer ${User.token}` },
			data: { token: encryptData({}) },
		}).then((response) => {
			response.data.data = decryptData(response.data.data)
			setPaymentGateway(response.data.data.payload[0].name)
			Set_Payment_Gateways(response.data.data.payload.map((Payment_Gateway: any) => Payment_Gateway.name));
		}).catch((error) => {
			console.log(error);
		});
	}, []);

	const getFundRequests = () => {
		setLoading(true)
		const token = localStorage.getItem("token");
		let data = {}
		if (startDate && endDate) {
		  data = {
			startDate: dateTime(startDate),
			endDate: dateTime(endDate)
		  };
		} else if (fundId === "alldata") {
		  data = {}
		} else {
		//   const currentDate = new Date().toISOString().split('T')[0];
		  const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
		  data = {
			startDate: dateTime(currentDate),
			endDate: dateTime(currentDate)
		  };
		}
		if (token) {
		  let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/SubAdmin/fund-request`,
			headers: {
			  Authorization: `Bearer ${token}`,
			},
			data: { token: encryptData(data) }
		  };
	
		  axios
			.request(config)
			.then((response) => {
			  response.data.data = decryptData(response.data.data)
			  setTotalWithdrawData(response.data.data.payload.WithdrawalData)
			  setLoading(false);
			})
			.catch((error) => {
			  console.log(error);
			  setLoading(false);
			});
		}
	  }


	useEffect(() => {
		filterTransaction();
		getFundRequests()
	}, [currentPage, itemsPerPage,fetchAllData]);
	useEffect(() => {
	}, [withdrawData]);

	const handlePerPage = (newValue: any) => {
		setItemsPerPage(newValue)
	}

	const filterTransactionData = (e: any) => {
		const id = e.target.id
		setFundId(id)
		if (id === "alldata") {
			setStartDate('')
			setEndDate('')	
		    setCurrentPage(1)
		    setFetchAllData("test")
			getFundRequests()
		} else {
		  setFetchAllData("")
		  filterTransaction()
		  setCurrentPage(1)
		  getFundRequests()
		}
	  }

	const filterTransaction = (event?: any) => {
		const id = event?.currentTarget?.id;
		setLoading(true);
		let filter: { mobile?: string, status?: string, ifscCode?: string, accountNo?: string, dp_id?: string, accountHolderName?: string, amount?: string, transactionId?: string } = {};
		if (searchUserName) {
			filter.accountHolderName = searchUserName
		}
		if (searchUserAmount) {
			filter.amount = searchUserAmount
		}
		if (searchUserTransactionId) {
			filter.transactionId = searchUserTransactionId
		}
		if (searchUserDpId) {
			filter.dp_id = searchUserDpId
		}
		if (searchUserAccountNo) {
			filter.accountNo = searchUserAccountNo
		}
		if (searchUserIfsc) {
			filter.ifscCode = searchUserIfsc
		}
		if (searchUserMobileNo) {
			filter.mobile = searchUserMobileNo
		}
		if (event?.target?.value == 'All') {
			setSearchUserStatus('All')
			filter = {}
		} else {
			setSearchUserStatus(event?.target?.value);
			filter.status = event?.target?.value
		}
		const token = localStorage.getItem("token");
		let data = {}
		if (fetchAllData) {
			data = {
				type: withdrawl,
				itemsPerPage: itemsPerPage,
				pageNo: currentPage,
				filter: filter
			};
		} else if (startDate && endDate) {
			data = {
				type: withdrawl,
				itemsPerPage: itemsPerPage,
				pageNo: currentPage,
				filter: filter,
				startDate: dateTime(startDate),
				endDate: dateTime(endDate)
			};
		} else {
			// const currentDate = new Date().toISOString().split('T')[0];
			const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
			data = {
				type: withdrawl,
				itemsPerPage: itemsPerPage,
				pageNo: currentPage,
				filter: filter,
				startDate: dateTime(currentDate),
				endDate: dateTime(currentDate)
			};
		}
		if (token) {
			let config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_Endpoint}/transaction/getAllTransaction`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: { token: encryptData(data) }
			};
			axios
				.request(config)
				.then((response) => {
					response.data.data = decryptData(response.data.data)
					setTotalUser(response.data.data.payload.total)
					let data = response.data.data.payload.items.length <= 0
					if (data) {
						toast("No withdrawals available for todays date")
					}
					setWithdrawData(response.data.data.payload.items);
					setTotalPages(response.data.data.payload.totalPages);
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		}

	};

	const handleOpenPopUp = (orderId?: string, action?: string, dp_id?: string) => {
		setOpenPopup(true)
		setOrderIdPopup(orderId ? orderId : "")
		setActionPopup(action ? action : "")
		setDpIdPopup(dp_id ? dp_id : "")
	}

	const checkedBy = (itemId: string, check: string, status: boolean) => {
		let data = {
			transactionId: itemId,
			check: check,
			updatedBy: {
				name: User.data.name,
				userId: User.data._id,
				status: status
			}
		};
		const token = localStorage.getItem("token");
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/transaction/check-withdrawal`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			data: { token: encryptData(data) },
		};

		axios
			.request(config)
			.then((response) => {
				if (response.data.success === true) {
					filterTransaction();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const Change_Payment_Gateway = (Index_of_Selected_Payment_Gateway: number, Index: number) => {
		setPaymentGateway(Payment_Gateways[Index_of_Selected_Payment_Gateway])
		const Copy_of_Selected_Payment_Gateway_Indexes: number[] = [...Selected_Payment_Gateway_Indexes];
		Copy_of_Selected_Payment_Gateway_Indexes[Index] = Index_of_Selected_Payment_Gateway;
		Set_Selected_Payment_Gateway_Indexes(Copy_of_Selected_Payment_Gateway_Indexes);
	}


	const handleSubmit = () => {
		setLoading(true);
		let data = {
			withdrewalProviderName: paymentGateway,
			transactionId: orderIdPopup,
			reason: actionPopup === "Approved" ? "Approved" : remark,
			dp_id: dpIdPopup,
			updatedBy: {
				name: User.data.name,
				_id: User.data._id,
				status: actionPopup,
			}
		};
		const token = localStorage.getItem("token");
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/transaction/withdrawal-status-update`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: { token: encryptData(data) },
		};

		axios
			.request(config)
			.then(() => {
				setLoading(false);
				setOpenPopup(false)
				setRemark("")
				filterTransaction();
			})
			.catch((error) => {
				console.log(error);
				toast(`${error.response.status} ${error.response.statusText}`)
				setLoading(false)
				setOpenPopup(false)
			});
	}

	const handleRemark = (e: ChangeEvent<HTMLInputElement>) => {
		setRemarkError(false);
		setRemark(e.target.value);
	};

	const clearDate = () => {
		setStartDate('')
		setEndDate('')
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="g-sidenav-show  bg-gray-100">
					<ToastContainer autoClose={2000} position="top-center" />
					<Sidenav />
					<main className="main-content position-relative">
						<div style={{ background: "#f8f9fa" }}>
							<Breadcrumbs tab={"Withdrawal"} />
							<div>
								<Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
									<DialogContent>
										<form onSubmit={handleSubmit}>
											{actionPopup === "Approved" ? <div className="d-flex justify-content-center">
												<p>Are you sure ?</p>
											</div> : <div>
												<Reusable_Input type={"text"} label={"Please enter remark"} fullWidth={true} value={remark} error={remarkError} helperText={remarkHelperText} onChange={handleRemark} />
											</div>}
											<DialogActions>
												<Button
													className="btn-popup"
													variant="outlined"
													onClick={() => setOpenPopup(false)}
													color="primary"
												>
													Cancel
												</Button>
												<Button
													className="btn-popup"
													variant="outlined"
													type="submit"
													color="primary"
												>
													Submit
												</Button>
											</DialogActions>
										</form>
									</DialogContent>
								</Dialog>
							</div>
							<div className="container-fluid">
								<div className="row">
									<div className="row tp-form mb-2">
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">From Date</label>
											<input
												type="date"
												className="form-control"
												placeholder="From Date"
												value={startDate}
												onChange={(e) => setStartDate(e.target.value)}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">To Date</label>
											<input
												type="date"
												className="form-control"
												placeholder="To Date"
												value={endDate}
												onChange={(e) => setEndDate(e.target.value)}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">Items Per Page</label>
											<div className="mt-1">
												<Stateful_Select
													value={itemsPerPage.toString()}
													onChange={(newValue: any) => handlePerPage(newValue)}
													options={["10", "25", "50", "75", "100"]}
													className="deposit-select"
												/>
											</div>

										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={filterTransactionData} className="sechBtn mt-1">
												Apply
											</a>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a id="alldata" onClick={(e) => filterTransactionData(e)} className="sechBtn mt-1">
												All Data
											</a>
										</div>
										
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={clearDate} className="sechBtn mt-1">
												Clear Dates
											</a>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>Total User : {totalUser}</b>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>Approved {`(${totalWithdrawData?.totalApprovedCount ? totalWithdrawData?.totalApprovedCount : 0}) : ${totalWithdrawData?.totalApprovedAmount ? totalWithdrawData?.totalApprovedAmount : 0}`}</b>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>Rejected {`(${totalWithdrawData?.totalRejectedCount ? totalWithdrawData?.totalRejectedCount : 0}) : ${totalWithdrawData?.totalRejectedAmount ? totalWithdrawData?.totalRejectedAmount : 0}`}</b>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>Pending {`(${totalWithdrawData?.totalPendingCount ? totalWithdrawData?.totalPendingCount : 0}) : ${totalWithdrawData?.totalPendingAmount ? totalWithdrawData?.totalPendingAmount : 0}`}</b>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>Reverse {`(${totalWithdrawData?.totalReversedCount ? totalWithdrawData?.totalReversedCount : 0}) : ${totalWithdrawData?.totalReversedAmount ? totalWithdrawData?.totalReversedAmount : 0}`}</b>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>On Hold {`(${totalWithdrawData?.totalOnholdCount ? totalWithdrawData?.totalOnholdCount : 0}) : ${totalWithdrawData?.totalOnholdAmount ? totalWithdrawData?.totalOnholdAmount : 0}`}</b>
										</div>
										<div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center mt-3">
											<label className="lbl"></label>
											<b>Cancelled {`(${totalWithdrawData?.totalCanceledCount ? totalWithdrawData?.totalCanceledCount : 0}) : ${totalWithdrawData?.totalCanceledAmount ? totalWithdrawData?.totalCanceledAmount : 0}`}</b>
										</div>
									</div>
									<div className="col-12 mt-1">
										<div className="table-responsive">
											<table className="table table-view">
												<thead>
													<tr>
														<th></th>
														<th>User Name</th>
														<th>Amount</th>
														<th>Commission Amount</th>
														<th>Transaction Id</th>
														<th>Dp Id</th>
														<th>Account No</th>
														<th>Bank Name</th>
														<th>IFSC</th>
														<th>Mobile NO</th>
														<th>Date</th>
														<th>Time</th>
														<th>Status</th>
														<th>Check By</th>
														<th>Cross Check By</th>
														<th>Withdrawal Provider</th>
														<th>Action</th>
														<th>Updated By</th>
													</tr>
												</thead>
												<thead>
													<tr className="bg-table">
														<th className="thdr">
														</th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserName} onChange={handleSearchUserName} onSearch={filterTransaction} placeholder="Search by user name" />
															</div>
														</th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserAmount} onChange={handleSearchUserAmount} onSearch={filterTransaction} placeholder="Search by amount" />
															</div>
														</th>
														<th className="thdr">
														</th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserTransactionId} onChange={handleSearchUserTransactionId} onSearch={filterTransaction} placeholder="Search by transaction id" />
															</div>
														</th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserDpId} onChange={handleSearchUserDpId} onSearch={filterTransaction} placeholder="Search by dp id" />
															</div>
														</th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserAccountNo} onChange={handleSearchUserAccountNo} onSearch={filterTransaction} placeholder="Search by account no" />
															</div>
														</th>
														<th className="thdr"></th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserIfsc} onChange={handleSearchUserIfsc} onSearch={filterTransaction} placeholder="Search by ifsc no" />
															</div>
														</th>
														<th className="thdr">
															<div className="d-flex justify-content-center">
																<SearchBar value={searchUserMobileNo} onChange={handleSearchUserMob} onSearch={filterTransaction} placeholder="Search by mobile no" />
															</div>
														</th>
														<th className="thdr"></th>
														<th className="thdr"></th>
														<th className="thdr">
															<div className="d-flex justify-content-center withdraw-select">
																<FormControl fullWidth>
																	<InputLabel id="demo-simple-select-label">Select Status</InputLabel>
																	<Select
																		labelId="demo-simple-select-label"
																		id="demo-simple-select"
																		value={searchUserStatus}
																		label="Age"
																		onChange={filterTransaction}
																	>
																		<MenuItem value={"All"}>All</MenuItem>
																		<MenuItem value={"Pending"}>Pending</MenuItem>
																		<MenuItem value={"Approved"}>Approved</MenuItem>
																		<MenuItem value={"Rejected"}>Rejected</MenuItem>
																		<MenuItem value={"Reverse"}>Reverse</MenuItem>
																		<MenuItem value={"on hold"}>on hold</MenuItem>
																		<MenuItem value={"Cancel"}>Canceled by customer</MenuItem>
																	</Select>
																</FormControl>
															</div>
														</th>
														<th className="thdr"></th>
														<th className="thdr"></th>
														<th className="thdr"></th>
														<th className="thdr"></th>
														<th className="thdr"></th>
													</tr>
												</thead>
												<tbody>
													{withdrawData.map((item, Index) => (
														<tr className={
															item.status === "Approved" ? "withdraw-approved-clr" :
																item.status === "Pending" ? "withdraw-pending-clr" :
																	item.status === "Rejected" ? "withdraw-rejected-clr" :
																		item.status === "on hold" ? "withdraw-onhold-clr" :
																			item.status === "Reverse" ? "withdraw-reverse-clr" :
																				""
														} >
															<td>{Index + 1}</td>
															<td
																id={item._id} style={{ cursor: "pointer" }}
																onClick={() => {
																	const url = `/user-report/${item.dp_id}/${item.accountHolderName}`;
																	window.open(url, "_blank");
																}}
															>
																{item.accountHolderName}
															</td>
															<td>
																{item.amount}
															</td>
															<td>
																{item.commissionAmount}
															</td>
															<td>
																{item.transactionId}
															</td>
															<td>
																{item.dp_id}
															</td>
															<td>
																{item.accountNo}
															</td>
															<td>
																{item.bankName}
															</td>
															<td>
																{item.ifscCode}
															</td>
															<td>
																{item.mobile}
															</td>
															<td>
																{formatDate(item.createdOn)}
															</td>
															<td>
																{formatedTime(item.createdOn)}
															</td>
															<td>
																{item.status}
															</td>
															<td>
																<div>
																	{item.checkBy ? (
																		item.checkBy.status === true ?
																			(
																				<span>
																					{`Ok by ${item.checkBy.name}`} <br/>
																					{`${formatDate(item.checkBy?.date)} ${formatedTime(item.checkBy?.date)}`}
																				</span>
																			) :
																			(
																				<span>
																					{`Not Ok by ${item.checkBy.name}`} <br/>
																					{`${formatDate(item.checkBy?.date)} ${formatedTime(item.checkBy?.date)}`}
																				</span>
																			)
																	)
																		:
																		<>
																			<span
																				onClick={() =>
																					checkedBy(item.transactionId, 'first', true)
																				}
																			>
																				<FontAwesomeIcon
																					className="fa fa-pencil-square icon-home icon-banner checkd"
																					icon={faSquareCheck}
																				/>
																			</span>
																			<span
																				onClick={() =>
																					checkedBy(item.transactionId, 'first', false)
																				}
																			>
																				<FontAwesomeIcon
																					className="fa fa-pencil-square icon-home icon-banner crossed"
																					icon={faRectangleXmark}
																				/>
																			</span>
																		</>

																	}

																</div>
															</td>
															<td>
																<div>
																	{item.crossCheckBy ? (
																		<span>
																			{item.crossCheckBy.status
																				?(
																				<span>
																					{`Ok by ${item.crossCheckBy.name}`}<br/>
																					{`${formatDate(item.crossCheckBy?.date)} ${formatedTime(item.crossCheckBy?.date)}`}
																				</span>
																				) 
																				:
																				(
																				<span>
																					{`Not Ok by ${item.crossCheckBy.name}`}<br/>
																					{`${formatDate(item.crossCheckBy?.date)} ${formatedTime(item.crossCheckBy?.date)}`}
																				</span>
																				)}
																		</span>
																	) : item?.checkBy?.status ? (
																		<>
																			<span onClick={() => checkedBy(item.transactionId, 'second', true)}>
																				<FontAwesomeIcon
																					className="fa fa-pencil-square icon-home icon-banner checkd"
																					icon={faSquareCheck}
																				/>
																			</span>
																			<span onClick={() => checkedBy(item.transactionId, 'second', false)}>
																				<FontAwesomeIcon
																					className="fa fa-pencil-square icon-home icon-banner crossed"
																					icon={faRectangleXmark}
																				/>
																			</span>
																		</>
																	) : null}
																</div>
															</td>
															<td>
																{
																	item.status === "Pending" ||  item.status === "on hold" ?
																	<CustomSelect
																		label="Select Gateway"
																		value={Selected_Payment_Gateway_Indexes[Index]}
																		onChange={(newValue) => Change_Payment_Gateway(newValue, Index)}
																		options={Payment_Gateways.map((gateway, index) => ({ value: index, label: gateway }))}
																	/>
																	: (item.status == "Approved")  ?
																	`${item.withdrewalProviderName} - ${item.mid}` : ''
																}
																
															</td>
															<td>
																{((item?.checkBy?.status && item?.crossCheckBy?.status) || (item.status == 'on hold')) && (item.status !== 'Processing') ?
																	<>
																		<span>
																			<Button
																				onClick={() => handleOpenPopUp(item.transactionId, "Reverse", item.dp_id)}
																				className="withdraw-btn"
																				variant="contained"
																				disabled={(!item.checkBy?.status && !item.crossCheckBy?.status && item.status !== "on hold") || (item.status === "Reverse" || item.status === "Approved" || item.status === "Rejected")}
																			>
																				Reverse
																			</Button>
																		</span>
																		<span>
																			<Button
																				onClick={() => handleOpenPopUp(item.transactionId, "Approved", item.dp_id)}
																				className="btn-withdraw"
																				variant="contained"
																				disabled={(!item.checkBy?.status && !item.crossCheckBy?.status && item.status !== "on hold") || item.status === "Reverse" || item.status === "Approved" || item.status === "Rejected"}
																			>
																				Approve
																			</Button>
																		</span>
																		<span>
																			<Button
																				onClick={() => handleOpenPopUp(item.transactionId, "Rejected", item.dp_id)}
																				className="btn-withdraw"
																				variant="contained"
																				disabled={(!item.checkBy?.status && !item.crossCheckBy?.status && item.status !== "on hold") || item.status === "Reverse" || item.status === "Approved" || item.status === "Rejected"}
																			>
																				Reject
																			</Button>
																		</span>
																		<span>
																			<Button
																				onClick={() => handleOpenPopUp(item.transactionId, "Manual Approved", item.dp_id)}
																				className="btn-withdraw"
																				variant="contained"
																				disabled={(!item.checkBy?.status && !item.crossCheckBy?.status && item.status !== "on hold") || item.status === "Reverse" || item.status === "Approved" || item.status === "Rejected"}
																			>
																				Manual
																			</Button>
																		</span>
																		</>
																		
																	:
																	<></>
																}
															</td>
															<td>{item.action ? `${item.action?.status} by ${item.action?.name}` : ""}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										<ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPages}
													color="secondary"
													page={currentPage}
													onChange={(Event, New_Page) =>
														setCurrentPage(New_Page)
													}
												/>
											</Stack>
										</ul>
									</div>
								</div>
								<footer className="footer">
									<div className="container-fluid">
										<div className="row align-items-center justify-content-lg-between">
											<div className="col-lg-6 mb-lg-0  ">
												<div className="copyright text-center text-sm text-muted text-lg-start">
													© fairbets.co
												</div>
											</div>
											<div className="col-lg-6">
												<ul className="nav nav-footer justify-content-center justify-content-lg-end">
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															Home
														</a>
													</li>
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															About Us
														</a>
													</li>
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															Blog
														</a>
													</li>
													<li className="nav-item">
														<a
															className="nav-link pe-0 text-muted"
															target="_blank"
														>
															License
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</footer>
							</div>
						</div>
					</main>
				</div>
			)}
		</>
	);
}

export default Withdraw;