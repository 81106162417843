import { useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { User_Context } from "../../Contexts/User";
import { useParams } from "react-router-dom";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { faTrashCan, faPlus, faList12, faListDots, faListUl, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Sidenav from "../../Components/SideNavigation/SideNavigation";
import Loader from "../../Components/Loader/Loader";

// Views
import WalletHistoryMaster from "./Views/WalletHistory/WalletHistoryMaster";

// Styles
import './User_Report.css'

interface User_Report_Data {
    _id: string,
    pendingWithdrawals: any[],
    pendingWithdrawal_sum: number,
    profit_and_loss: number,
    exposure: number,
    balance: number,
    betHistory: any[],
    userGgr: number
}

function User_Report_Master() {
    const [Financial_Report, Set_Financial_Report] = useState<User_Report_Data>({
        _id: '',
        pendingWithdrawals: [],
        pendingWithdrawal_sum: 0,
        profit_and_loss: 0,
        exposure: 0,
        balance: 0,
        betHistory: [],
        userGgr: 0
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { User_ID, userName, pageNo, table } = useParams();

    const { User } = useContext(User_Context)

    const navigate = useNavigate();

    const [selectedComponent, setSelectedComponent] = useState('wallet_history')
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="g-sidenav-show  bg-gray-100">
                    <ToastContainer autoClose={2000} position="top-center" />
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#f8f9fa" }}>
                        <div style={{marginLeft:"10px",cursor:"pointer"}} onClick={() => navigate("/master-flow",{ state: {page:pageNo,table:table}})}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <span className="user-name"> Back</span>
                        </div>
                            <div className="container-fluid py-1 px-3">
                                <nav aria-label="breadcrumb" className="breadcrumbs">
                                    <ol className="breadcrumb bg-transparent mb-0 px-0">
                                        <li className="breadcrumb-item">
                                            <span className="opacity-5 text-dark">User : {userName}</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="container-fluid">
                                {selectedComponent === "wallet_history" &&
                                    <WalletHistoryMaster
                                        type={'wallet'}
                                    />
                                }
                            </div>
                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row align-items-center justify-content-lg-between">
                                        <div className="col-lg-6 mb-lg-0  ">
                                            <div className="copyright text-center text-sm text-muted text-lg-start">
                                                © fairbets.co
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        About Us
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Blog
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link pe-0 text-muted"
                                                        target="_blank"
                                                    >
                                                        License
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </main>
                </div>
            )}
        </>
    )
}

export default User_Report_Master